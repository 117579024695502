/**
 _buttons
*/

.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 50px;

  font-family: $font-heading;
  font-size: $font-size-medium;

  font-weight: $font-weight-normal;
  line-height: 21px;
  text-align: center;

  padding: 0.5rem 1.5rem;

  outline: none;
  border: none !important;
  border-radius: 0px !important;

  transition: all 0.2s linear;

  background: transparent;
  color: $blue-500;

  &:hover {
    background-color: $blue-500;
    opacity: unset !important;
  }

  &:disabled {
    background-color: $dark-700;
    opacity: 1;
  }

  @media (max-width: 480px) {
    min-width: 90px;
    font-size: $font-size-medium;
    line-height: 28px;
  }
}

.btn-primary {
  @extend .btn;
  background: $blue-500  !important;
  color: $white;
  font-size: $font-size-xmedium  !important;
  font-weight: 600 !important;
}

.btn-secondary {
  @extend .btn;
  color: $white;
  border: 1px ridge $white;
  font-size: $font-size-xmedium  !important;
  font-weight: 600 !important;
}

.btn-tertiary {
  @extend .btn;
  color: $white;
  background: $blue-500;
}

.btn-outline-white {
  @extend .btn;
  background-color: $white  !important;
  color: $blue-500  !important;
  font-size: $font-size-xmedium  !important;
  border: 1px solid $blue-500  !important;
  font-weight: 600 !important;
}

.btn-outline-blue {
  @extend .btn;
  background-color: $blue  !important;
  color: $white  !important;
  font-size: $font-size-xmedium  !important;
  border: 1px solid $white  !important;
  font-weight: 600 !important;
}