@import './variables';
@import './buttons';
@import '/node_modules/@infrab4a/components-b4a/src/themes/mens.theme';

:root {
  //Instagram
  --svg-outline-next-arrow-instagram: url(./../../assets/img/svg/outline-next-arrow.svg);
  --svg-outline-previous-arrow-instagram: url(./../../assets/img/svg/outline-previous-arrow.svg);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-base;
  background: $white;
  color: $dark-700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button {
  font-family: $font-heading;
  font-weight: 400;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-base;
  font-weight: 600;
}

h1 {
  font-size: $font-size-xlarge;
}

h2,
h3 {
  font-size: $font-size-large;
}

h4,
h5 {
  font-size: $font-size-xxmedium;
}

h6 {
  font-size: $font-size-xmedium;
}

button {
  cursor: pointer;
}

[disabled] {
  background-color: #A4A4A4 !important;
  color: #FFFFFF;
  cursor: not-allowed;
}

a {
  color: $gray-500;
  text-decoration: none;
  transition: all 0.2s linear;

  &:hover {
    color: $gray-500;
  }
}

p {
  font-weight: 500;
}

strong {
  font-weight: 600;
  color: $dark-700;
}

// html {
//   @media (max-width: 1080px) {
//     font-size: 93.75%;
//   }

//   @media (max-width: 720px) {
//     font-size: 87.5%;
//   }
// }

/* Alerts */

.danger {
  border-color: $brown;
  color: $brown;

  font-size: $font-size-small;
}

.mp-values {
  .wrapper {
    &__title {
      margin-bottom: 40px;
      font-family: var(--font-secondary) !important;
    }

    &__button button {
      font-size: 1rem !important;
      font-weight: 500 !important;
      font-family: var(--font-secondary) !important;
    }
  }

  .content .circle {
    width: 128px;
    height: 178px;
  }
}